export const initialState = {
    isShowMediaRecordComponents: false,
    isInternationalization: false,
    isOptimizingImg: false,
    showMaintenance: false,
    isLoading: true,
};

export function reducer(state = initialState, { type, payload }) {
    switch (type) {
        case "SHOWMEDIARECORD":
            return {
                ...state,
                isShowMediaRecordComponents: payload === "true",
            };

        case "INTERNATIONALIZATION":
            return {
                ...state,
                isInternationalization: payload === "true",
            };

        case "ISOPTIMIZINGIMG":
            return {
                ...state,
                isOptimizingImg: payload === "true",
            };

        case "SHOWMAINTENANCE":
            return {
                ...state,
                showMaintenance: payload === "true",
            };

        case "LOADING":
            return {
                ...state,
                isLoading: payload,
            };

        default:
            return state;
    }
}
