// import 'tailwindcss/tailwind.css'
import React from "react";
import "../styles/globals.css";
import LanguageProvider from "../context/LanguageContext";
import GlobalProvider from "../context/GlobalContext";

function MyApp({ Component, pageProps }) {
    return (
        <GlobalProvider>
            <LanguageProvider>
                <Component {...pageProps} />
            </LanguageProvider>
        </GlobalProvider>
    );
}

export default MyApp;
