var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"lKirkpRAlqy1bqoJ9k0c2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import appMetadata from "./package.json";

Sentry.init({
    dsn: "https://45672ce22db047db9d3823ed22944f7c@o1318482.ingest.sentry.io/4504902223659008",
    autoSessionTracking: true,
    release: [appMetadata.name, appMetadata.version].join("@"),
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations:
        process.env.NODE_ENV === "production"
            ? [new Sentry.Replay()]
            : [
                  new Sentry.Replay(),
                  new Sentry.Integrations.Breadcrumbs({
                      console: false,
                  }),
              ],
    tracesSampler: () => (process.env.NODE_ENV === "production" ? 1 : 0.2),
    ignoreErrors: [
        "TypeError: Failed to fetch",
        "TypeError: NetworkError when attempting to fetch resource.",
        "SyntaxError: Unexpected token '<'",
        "UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:3",
        "InvalidStateError: Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.",
        "UnknownError: Database deleted by request of the user",
    ],
    beforeSend: (event) => {
        const regex =
            /Non-Error promise rejection captured with value: Object Not Found Matching[^]*?Id:\d+/gi;
        if (
            event !== undefined &&
            event.exception !== undefined &&
            event.exception.values !== undefined &&
            event.exception.values.length === 1
        ) {
            const e = event.exception.values[0];
            if (e.type === "UnhandledRejection" && regex.test(e.value)) {
                return null;
            }
        }
    },
});
