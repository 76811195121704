// constants used in all app
export const LANGUAGE = {
    es: {
        INPUT_FILE1: "Seleccionar un archivo",
        INPUT_FILE2: "Ningun archivo seleccionado",
        INDEX_FORM_TITLE: "¿Cómo fue tu experiencia con nosotros?",
        LABEL_CODE_FIELD: "Ingresa el código del punto o marca a evaluar",
        PLACEHOLDER_CODE_FIELD: "Código",
        INDEX_FORM_SUBMIT_TEXT_BUTTON: "Calificar",
        ERROR_PAGE_TITLE: "El link no existe",
        ERROR_PAGE_DESCRIPTION: "El link al que intentas acceder no existe",
        ERROR_PAGE_TEXT_BUTTON: "Ir al inicio",
        REQUIRED_QUESTION: "Pregunta requerida",
        CODE_DOESNT_EXIST: "El código no existe",
        CODE_INACTIVED: "El código esta inactivo",
        INVITATION_DOESNT_EXIST: "La invitación no existe",
        ERROR_TITLE_GET_FORM: "Ha ocurrido un error obteniendo el formulario",
        INVITATION_HAS_EXPIRED: "La invitación ha caducado",
        INVITATION_HAS_ANSWERED: "El formulario ya fue contestado",
        TOKEN_HAS_EXPIRED: "La invitación ha caducado",
        INVALID_TOKEN: "Invitación inválida",
        LOADER_MESSAGE: "Cargando...",
        LOADER_FORM_MESSAGE: "Cargando formulario...",
        LOADER_CHANGE_LANGUAGE: "Cambiando idioma...",
        PLACEHOLDER_SELECT_FIELD: "Seleccionar...",
        AUDIO: "Graba tu respuesta de voz",
        VIDEO: "Graba tu vídeo en vivo",
        SECONDS_TEXT: "segundos",
        DOES_NOT_SUPPORT_VIDEOS: "Sorry, your browser doesn't support videos.",
        YOUR_SCORING: "Tu resultado",
        BRAND_TITLE: "Marca",
        CODE_TITLE: "Código",
        EXPERIENCE_TITLE: "Experiencia",
        PROCESS_TITLE: "Proceso",
        POINT_TITLE: "Punto",
        DOWNLOAD_TEXT: "Descargar",
        QR_CODE_ERROR: "El código no existe o está inactivo",
        EMOTIONS_PLACEHOLDER: "Seleccionar emoción...",
        HOW_WAS_FELT_PLACEHOLDER: "¿Cómo se sintió?",
        HOW_WISH_IT_WAS_PLACEHOLDER: "¿Cómo desearía que fuera?",
        MORE_INFO_PLACEHOLDER: "Ampliar más información",
        EVIDENCE_PLACEHOLDER: "Subir evidencia",
        DEFAULT_PLACEHOLDER_INPUTNUMBER: "Por favor escriba un número",
        DEFAULT_PLACEHOLDER_INPUTTEXT: "Por favor escriba su respuesta",
        DEFAULT_ERROR_MESSAGE_REGEXP: "No cumple la expresion regular",
        DEFAULT_MIN_TEXT_SCALE: "Nada satisfecho",
        DEFAULT_MAX_TEXT_SCALE: "Muy satisfecho",
        YES_LABEL: "Si",
        NO_LABEL: "No",
        DE_LABEL: "de",
        PAGE_LABEL: "Página",
        PERCENT_LABEL: "respondido",
        MAINTENANCE:
            "Cemtrik se encuentra en esta momento en un mantenimiento programado, estará completamente disponible a las 4:00 AM GMT-5",
        ERROR_RESPONSES_WITHOUT_INVITATION:
            "No tienes una invitación vigente para acceder a este vínculo",
    },
    en: {
        INPUT_FILE1: "Select a file",
        INPUT_FILE2: "No file selected",
        INDEX_FORM_TITLE: "How was your experience with us?",
        LABEL_CODE_FIELD: "Please input the code of the point or mark for evaluation.",
        PLACEHOLDER_CODE_FIELD: "Code",
        INDEX_FORM_SUBMIT_TEXT_BUTTON: "Rate",
        ERROR_PAGE_TITLE: "The link does not exist",
        ERROR_PAGE_DESCRIPTION: "The link you are trying to access does not exist",
        ERROR_PAGE_TEXT_BUTTON: "Go to the beginning",
        REQUIRED_QUESTION: "Required question",
        CODE_DOESNT_EXIST: "The code does not exist",
        CODE_INACTIVED: "The code is inactive",
        INVITATION_DOESNT_EXIST: "The invitation does not exist",
        ERROR_TITLE_GET_FORM: "An error occurred while retrieving the form",
        INVITATION_HAS_EXPIRED: "The invitation has expired",
        INVITATION_HAS_ANSWERED: "The form has already been answered",
        TOKEN_HAS_EXPIRED: "The invitation has expired",
        INVALID_TOKEN: "Invalid invitation",
        LOADER_MESSAGE: "Loading...",
        LOADER_FORM_MESSAGE: "Loading form...",
        LOADER_CHANGE_LANGUAGE: "Changing language...",
        PLACEHOLDER_SELECT_FIELD: "Select...",
        AUDIO: "Record your voice response",
        VIDEO: "Record your live video",
        SECONDS_TEXT: "seconds",
        DOES_NOT_SUPPORT_VIDEOS: "Sorry, your browser doesn't support videos.",
        YOUR_SCORING: "Your result",
        BRAND_TITLE: "Mark",
        CODE_TITLE: "Code",
        EXPERIENCE_TITLE: "Experience",
        PROCESS_TITLE: "Process",
        POINT_TITLE: "Point",
        DOWNLOAD_TEXT: "Download",
        QR_CODE_ERROR: "The code does not exist or is inactive",
        EMOTIONS_PLACEHOLDER: "Select emotion...",
        HOW_WAS_FELT_PLACEHOLDER: "How did it feel?",
        HOW_WISH_IT_WAS_PLACEHOLDER: "How would you like it to be?",
        MORE_INFO_PLACEHOLDER: "Expand for more information",
        EVIDENCE_PLACEHOLDER: "Upload evidence",
        DEFAULT_PLACEHOLDER_INPUTNUMBER: "Please enter a number",
        DEFAULT_PLACEHOLDER_INPUTTEXT: "Please write your response",
        DEFAULT_ERROR_MESSAGE_REGEXP: "Does not match the regular expression",
        DEFAULT_MIN_TEXT_SCALE: "Not at all satisfied",
        DEFAULT_MAX_TEXT_SCALE: "Very satisfied",
        YES_LABEL: "Yes",
        NO_LABEL: "No",
        DE_LABEL: "of",
        PAGE_LABEL: "Page",
        PERCENT_LABEL: "answered",
        MAINTENANCE:
            "Cemtrik is currently undergoing scheduled maintenance and will be fully available at 4:00 AM GMT-5",
        ERROR_RESPONSES_WITHOUT_INVITATION: "You don't have a valid invitation to access this link",
    },
    fr: {
        INPUT_FILE1: "Sélectionner un fichier",
        INPUT_FILE2: "Aucun fichier sélectionné",
        INDEX_FORM_TITLE: "Comment a été votre expérience avec nous ?",
        LABEL_CODE_FIELD: "Veuillez entrer le code du point ou de la marque à évaluer",
        PLACEHOLDER_CODE_FIELD: "Code",
        INDEX_FORM_SUBMIT_TEXT_BUTTON: "Noter",
        ERROR_PAGE_TITLE: "Le lien n'existe pas",
        ERROR_PAGE_DESCRIPTION: "Le lien auquel vous essayez d'accéder n'existe pas",
        ERROR_PAGE_TEXT_BUTTON: "Aller au début",
        REQUIRED_QUESTION: "Question obligatoire",
        CODE_DOESNT_EXIST: "Le code n'existe pas",
        CODE_INACTIVED: "Le code est inactif",
        INVITATION_DOESNT_EXIST: "L'invitation n'existe pas",
        ERROR_TITLE_GET_FORM: "Une erreur s'est produite lors de la récupération du formulaire",
        INVITATION_HAS_EXPIRED: "L'invitation a expiré",
        INVITATION_HAS_ANSWERED: "Le formulaire a déjà été répondu",
        TOKEN_HAS_EXPIRED: "L'invitation a expiré",
        INVALID_TOKEN: "Invitation invalide",
        LOADER_MESSAGE: "Chargement...",
        LOADER_FORM_MESSAGE: "Chargement du formulaire...",
        LOADER_CHANGE_LANGUAGE: "Changer de langue...",
        PLACEHOLDER_SELECT_FIELD: "Sélectionner...",
        AUDIO: "Enregistre votre réponse vocale",
        VIDEO: "Enregistrez votre vidéo en direct",
        SECONDS_TEXT: "secondes",
        DOES_NOT_SUPPORT_VIDEOS: "Désolé, votre navigateur ne prend pas en charge les vidéos.",
        YOUR_SCORING: "Ton résultat",
        BRAND_TITLE: "Marque",
        CODE_TITLE: "Code",
        EXPERIENCE_TITLE: "Expérience",
        PROCESS_TITLE: "Processus",
        POINT_TITLE: "Point",
        DOWNLOAD_TEXT: "Télécharger",
        QR_CODE_ERROR: "Le code n'existe pas ou est inactif",
        EMOTIONS_PLACEHOLDER: "Sélectionner une émotion...",
        HOW_WAS_FELT_PLACEHOLDER: "Comment avez-vous ressenti?",
        HOW_WISH_IT_WAS_PLACEHOLDER: "Comment souhaiteriez-vous que ce soit?",
        MORE_INFO_PLACEHOLDER: "Élargir pour plus d'informations",
        EVIDENCE_PLACEHOLDER: "Télécharger une preuve",
        DEFAULT_PLACEHOLDER_INPUTNUMBER: "Veuillez entrer un nombre, s'il vous plaît",
        DEFAULT_PLACEHOLDER_INPUTTEXT: "Veuillez écrire votre réponse, s'il vous plaît",
        DEFAULT_ERROR_MESSAGE_REGEXP: "Ne correspond pas à l'expression régulière",
        DEFAULT_MIN_TEXT_SCALE: "Pas du tout satisfait",
        DEFAULT_MAX_TEXT_SCALE: "Très satisfait",
        YES_LABEL: "Oui",
        NO_LABEL: "Non",
        DE_LABEL: "de",
        PAGE_LABEL: "Page",
        PERCENT_LABEL: "répondu",
        MAINTENANCE:
            "Cemtrik est actuellement en cours de maintenance programmée et sera entièrement disponible à 4h00 GMT-5",
        ERROR_RESPONSES_WITHOUT_INVITATION:
            "Vous n'avez pas d'invitation en cours pour accéder à ce lien",
    },
    de: {
        INPUT_FILE1: "Eine Datei auswählen",
        INPUT_FILE2: "Keine Datei ausgewählt",
        INDEX_FORM_TITLE: "Wie war deine Erfahrung mit uns?",
        LABEL_CODE_FIELD: "Gib den Code des Punktes oder der Marke ein, die du bewerten möchtest",
        PLACEHOLDER_CODE_FIELD: "Code",
        INDEX_FORM_SUBMIT_TEXT_BUTTON: "Bewerten",
        ERROR_PAGE_TITLE: "Der Link existiert nicht",
        ERROR_PAGE_DESCRIPTION: "Der Link, auf den du zugreifen möchtest, existiert nicht.",
        ERROR_PAGE_TEXT_BUTTON: "Zur Startseite gehen",
        REQUIRED_QUESTION: "Erforderliche Frage",
        CODE_DOESNT_EXIST: "Der Code existiert nicht",
        CODE_INACTIVED: "Der Code ist inaktiv",
        INVITATION_DOESNT_EXIST: "Die Einladung existiert nicht",
        ERROR_TITLE_GET_FORM: "Ein Fehler ist beim Abrufen des Formulars aufgetreten.",
        INVITATION_HAS_EXPIRED: "Die Einladung ist abgelaufen",
        INVITATION_HAS_ANSWERED: "Das Formular wurde bereits beantwortet",
        TOKEN_HAS_EXPIRED: "Die Einladung ist abgelaufen",
        INVALID_TOKEN: "Ungültige Einladung",
        LOADER_MESSAGE: "Laden...",
        LOADER_FORM_MESSAGE: "Formular wird gelade...",
        LOADER_CHANGE_LANGUAGE: "Sprache ändern...",
        PLACEHOLDER_SELECT_FIELD: "Auswählen...",
        AUDIO: "Nimm deine Antwort mit deiner Stimme auf",
        VIDEO: "Nimm dein Live-Video auf",
        SECONDS_TEXT: "Sekunden",
        DOES_NOT_SUPPORT_VIDEOS: "Entschuldigung, Ihr Browser unterstützt keine Videos.",
        YOUR_SCORING: "Dein Ergebnis",
        BRAND_TITLE: "Marke",
        CODE_TITLE: "Code",
        EXPERIENCE_TITLE: "Erfahrung",
        PROCESS_TITLE: "Prozess",
        POINT_TITLE: "Punkt",
        DOWNLOAD_TEXT: "Herunterladen",
        QR_CODE_ERROR: "Der Code existiert nicht oder ist inaktiv",
        EMOTIONS_PLACEHOLDER: "Emotion auswählen...",
        HOW_WAS_FELT_PLACEHOLDER: "Wie haben Sie sich gefühlt?",
        HOW_WISH_IT_WAS_PLACEHOLDER: "Wie würden Sie es sich wünschen?",
        MORE_INFO_PLACEHOLDER: "Weitere Informationen anzeigen",
        EVIDENCE_PLACEHOLDER: "Beweismaterial hochladen",
        DEFAULT_PLACEHOLDER_INPUTNUMBER: "Bitte geben Sie eine Zahl ein",
        DEFAULT_PLACEHOLDER_INPUTTEXT: "Bitte schreiben Sie Ihre Antwort",
        DEFAULT_ERROR_MESSAGE_REGEXP: "Entspricht nicht dem regulären Ausdruck",
        DEFAULT_MIN_TEXT_SCALE: "Gar nicht zufrieden",
        DEFAULT_MAX_TEXT_SCALE: "Sehr zufrieden",
        YES_LABEL: "Ja",
        NO_LABEL: "Nein",
        DE_LABEL: "von",
        PAGE_LABEL: "Seite",
        PERCENT_LABEL: "beantwortet",
        MAINTENANCE:
            "Cemtrik befindet sich derzeit in planmäßiger Wartung und wird um 4:00 Uhr GMT-5 vollständig verfügbar sein",
        ERROR_RESPONSES_WITHOUT_INVITATION:
            "Sie haben keine gültige Einladung, um auf diesen Link zuzugreifen",
    },
    pt: {
        INPUT_FILE1: "Selecionar um arquivo",
        INPUT_FILE2: "Nenhum arquivo selecionado",
        INDEX_FORM_TITLE: "Como foi sua experiência conosco?",
        LABEL_CODE_FIELD: "Digite o código do ponto ou marca a ser avaliado",
        PLACEHOLDER_CODE_FIELD: "Código",
        INDEX_FORM_SUBMIT_TEXT_BUTTON: "Avaliar",
        ERROR_PAGE_TITLE: "O link não existe",
        ERROR_PAGE_DESCRIPTION: "O link que você está tentando acessar não existe",
        ERROR_PAGE_TEXT_BUTTON: "Ir para o início",
        REQUIRED_QUESTION: "Pergunta obrigatória",
        CODE_DOESNT_EXIST: "O código não existe",
        CODE_INACTIVED: "O código está inativo",
        INVITATION_DOESNT_EXIST: "O convite não existe",
        ERROR_TITLE_GET_FORM: "Ocorreu um erro ao obter o formulário",
        INVITATION_HAS_EXPIRED: "O convite expirou",
        INVITATION_HAS_ANSWERED: "O formulário já foi respondido",
        TOKEN_HAS_EXPIRED: "O convite expirou",
        INVALID_TOKEN: "Convite inválido",
        LOADER_MESSAGE: "Carregando...",
        LOADER_FORM_MESSAGE: "Carregando formulário...",
        LOADER_CHANGE_LANGUAGE: "Mudando idioma...",
        PLACEHOLDER_SELECT_FIELD: "Selecionar...",
        AUDIO: "Grave sua resposta de voz",
        VIDEO: "Grave seu vídeo ao vivo",
        SECONDS_TEXT: "segundos",
        DOES_NOT_SUPPORT_VIDEOS: "Desculpe, seu navegador não suporta vídeos.",
        YOUR_SCORING: "Sua pontuação",
        BRAND_TITLE: "Marca",
        CODE_TITLE: "Código",
        EXPERIENCE_TITLE: "Experiência",
        PROCESS_TITLE: "Processo",
        POINT_TITLE: "Ponto",
        DOWNLOAD_TEXT: "Baixar",
        QR_CODE_ERROR: "O código não existe ou está inativo",
        EMOTIONS_PLACEHOLDER: "Selecionar emoção...",
        HOW_WAS_FELT_PLACEHOLDER: "Como se sentiu?",
        HOW_WISH_IT_WAS_PLACEHOLDER: "Como gostaria que fosse?",
        MORE_INFO_PLACEHOLDER: "Forneça mais informações",
        EVIDENCE_PLACEHOLDER: "Enviar evidência",
        DEFAULT_PLACEHOLDER_INPUTNUMBER: "Por favor, digite um número",
        DEFAULT_PLACEHOLDER_INPUTTEXT: "Por favor, escreva sua resposta",
        DEFAULT_ERROR_MESSAGE_REGEXP: "Não atende à expressão regular",
        DEFAULT_MIN_TEXT_SCALE: "Nada satisfeito",
        DEFAULT_MAX_TEXT_SCALE: "Muito satisfeito",
        YES_LABEL: "Sim",
        NO_LABEL: "Não",
        DE_LABEL: "de",
        PAGE_LABEL: "Página",
        PERCENT_LABEL: "respondido",
        MAINTENANCE:
            "A Cemtrik está atualmente em manutenção programada, estará completamente disponível às 4:00 AM GMT-5",
        ERROR_RESPONSES_WITHOUT_INVITATION: "Você não tem um convite válido para acessar este link",
    },
    it: {
        INPUT_FILE1: "Seleziona un file",
        INPUT_FILE2: "Nessun file selezionato",
        INDEX_FORM_TITLE: "Com'è stata la tua esperienza con noi?",
        LABEL_CODE_FIELD: "Inserisci il codice del punto o del marchio da valutare",
        PLACEHOLDER_CODE_FIELD: "Codice",
        INDEX_FORM_SUBMIT_TEXT_BUTTON: "Valuta",
        ERROR_PAGE_TITLE: "Il link non esiste",
        ERROR_PAGE_DESCRIPTION: "Il link che stai cercando di accedere non esiste",
        ERROR_PAGE_TEXT_BUTTON: "Vai alla home",
        REQUIRED_QUESTION: "Domanda obbligatoria",
        CODE_DOESNT_EXIST: "Il codice non esiste",
        CODE_INACTIVED: "Il codice è inattivo",
        INVITATION_DOESNT_EXIST: "L'invito non esiste",
        ERROR_TITLE_GET_FORM: "Si è verificato un errore durante l'ottenimento del modulo",
        INVITATION_HAS_EXPIRED: "L'invito è scaduto",
        INVITATION_HAS_ANSWERED: "Il modulo è già stato risposto",
        TOKEN_HAS_EXPIRED: "L'invito è scaduto",
        INVALID_TOKEN: "Invito non valido",
        LOADER_MESSAGE: "Caricamento...",
        LOADER_FORM_MESSAGE: "Caricamento modulo...",
        LOADER_CHANGE_LANGUAGE: "Cambiamento lingua...",
        PLACEHOLDER_SELECT_FIELD: "Seleziona...",
        AUDIO: "Registrare la tua risposta vocale",
        VIDEO: "Registra il tuo video dal vivo",
        SECONDS_TEXT: "secondi",
        DOES_NOT_SUPPORT_VIDEOS: "Spiacente, il tuo browser non supporta i video.",
        YOUR_SCORING: "Il tuo risultato",
        BRAND_TITLE: "Marca",
        CODE_TITLE: "Codice",
        EXPERIENCE_TITLE: "Esperienza",
        PROCESS_TITLE: "Processo",
        POINT_TITLE: "Punto",
        DOWNLOAD_TEXT: "Scaricare",
        QR_CODE_ERROR: "Il codice non esiste o è inattivo",
        EMOTIONS_PLACEHOLDER: "Seleziona emozione...",
        HOW_WAS_FELT_PLACEHOLDER: "Come ti sei sentito?",
        HOW_WISH_IT_WAS_PLACEHOLDER: "Come desideri che fosse?",
        MORE_INFO_PLACEHOLDER: "Fornisci ulteriori informazioni",
        EVIDENCE_PLACEHOLDER: "Carica prove",
        DEFAULT_PLACEHOLDER_INPUTNUMBER: "Per favore inserisci un numero",
        DEFAULT_PLACEHOLDER_INPUTTEXT: "Per favore scrivi la tua risposta",
        DEFAULT_ERROR_MESSAGE_REGEXP: "Non soddisfa l'espressione regolare",
        DEFAULT_MIN_TEXT_SCALE: "Per niente soddisfatto",
        DEFAULT_MAX_TEXT_SCALE: "Molto soddisfatto",
        YES_LABEL: "Sì",
        NO_LABEL: "No",
        DE_LABEL: "di",
        PAGE_LABEL: "Pagina",
        PERCENT_LABEL: "risposto",
        MAINTENANCE:
            "Cemtrik è attualmente in manutenzione programmata, sarà completamente disponibile alle 4:00 AM GMT-5",
        ERROR_RESPONSES_WITHOUT_INVITATION: "Non hai un invito valido per accedere a questo link",
    },
};

export const LANGUAGESELECT = {
    en: {
        label: "English",
        value: "en",
    },
    es: {
        label: "Español",
        value: "es",
    },
    fr: {
        label: "Français",
        value: "fr",
    },
    de: {
        label: "Deutsch",
        value: "de",
    },
    pt: {
        label: "Português",
        value: "pt",
    },
    it: {
        label: "Italiano",
        value: "it",
    },
};
