// 1.- libraries
import React, { useEffect, useReducer, createContext, useMemo } from "react";
import { useRouter } from "next/router";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { reducer, initialState } from "./reducer";
import { remoteConfig } from "../config/firebaseApp";

export const GlobalContext = createContext({});

function GlobalProvider({ children }) {
    const router = useRouter();

    const [state, dispatch] = useReducer(reducer, initialState);

    const values = useMemo(
        () => ({
            state,
            dispatch,
        }),
        [state, dispatch],
    );

    useEffect(() => {
        dispatch({
            type: "LOADING",
            payload: true,
        });

        fetchAndActivate(remoteConfig)
            .then(() => {
                const internationalization = getValue(remoteConfig, "internationalization");
                const showMediaRecordComponents = getValue(
                    remoteConfig,
                    "showMediaRecordComponents",
                );
                const isOptimizingImg = getValue(remoteConfig, "isOptimizingImg");
                const showMaintenance = getValue(remoteConfig, "showMaintenance");

                dispatch({
                    type: "SHOWMEDIARECORD",
                    payload: showMediaRecordComponents["_value"],
                });
                dispatch({
                    type: "INTERNATIONALIZATION",
                    payload: internationalization["_value"],
                });
                dispatch({
                    type: "ISOPTIMIZINGIMG",
                    payload: isOptimizingImg["_value"],
                });
                dispatch({
                    type: "SHOWMAINTENANCE",
                    payload: showMaintenance["_value"],
                });

                dispatch({
                    type: "LOADING",
                    payload: false,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: "LOADING",
                    payload: false,
                });
            });
    }, []);

    useEffect(() => {
        if (router.asPath === "/maintenance") return;
        state.showMaintenance && router.push("/maintenance");
    }, [state, router]);

    return <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>;
}

export default GlobalProvider;
